<template>
  <div>
    <div class="">
      <span v-on:click="open()">Forgot Password</span>
    </div>
    <b-modal
      id="default-dialog"
      ref="userModal"
      @close="close"
      no-close-on-backdrop
      centered
      hide-footer
      :title="title"
    >
      <form @submit.prevent="save">
        <b-col class="p-0">
          <span class="info-text mb-2"
            >Enter your account email address to receive a link to reset your password.</span
          >
          <b-form-group class="custom-field-wrapper">
            <label class="custom-label">Email</label>

            <b-form-input
              :class="{ 'validation-error': $v.user.email.$error }"
              :state="validateState('email')"
              v-model="$v.user.email.$model"
              class="custom-field custom-field-gray-bg"
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.user.email.required"
              >Email must not be empty.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!$v.user.email.email"
              >Email must be valid.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col class="p-0 footer mt-2">
          <b-button
            class="mr-3"
            variant="outline-secondary"
            type="button"
            v-on:click="close"
          >
            Cancel
          </b-button>
          <b-button class="" variant="primary" type="submit">
            Send Email
          </b-button>
        </b-col>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  required,
  sameAs,
  numeric,
  minValue,
  maxValue,
  email,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "ForgotPasswordModal",
  mixins: [validationMixin],
  components: {},
  props: {
    title: {
      type: String,
      default: "Password Reset",
    },
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
    },
  },
  data() {
    return {
      user: {
        email: "",
      },
    };
  },
  computed: {},
  methods: {
    touchInvalidControls() {
      Object.keys(this.$v.user)
        .filter((key) => key.indexOf("$") === -1)
        .forEach((key) => {
          const control = this.$v.user[key];
          if (control.$invalid) {
            control.$touch();
          }
        });
    },
    isFormValid() {
      const isValid = !this.$v.user.$invalid;
      if (!isValid) this.touchInvalidControls();
      return isValid;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.user = {
        email: "",
      };
    },
    close() {
      this.resetForm();
      this.$refs.userModal.hide();
    },
    open() {
      this.$refs.userModal.show();
    },
    async save() {
      if (this.isFormValid()) {
        let response = await this.$store.dispatch(
          "handleSendForgotPasswordEmail",
          {
            email: this.user.email,
          }
        );
        if (response) {
          this.$v.$reset();
          this.resetForm();
          this.close();
        }
      }
    },
  },
};
</script>
<style lang="scss"></style>
