<template>
  <div class="login-wrapper">
    <div class="form-wrapper">
      <div>
        <div class="header-wrapper flex-row">
          <div class="header-left">
            <div class="flex row">
              <Logo></Logo>
            </div>
            <div class="header-title">WELCOME</div>
          </div>
        </div>
        <div class="inputs-wrapper">
          <div v-if="!showLoginForm && isAadEnabled"
            class="row login-buttons-wrapper"
          >
            <b-button
              variant="primary"
              class="lg login-aad-button"
              v-on:click="handleOauthLoginClick"
            >
              Login with Azure Active Directory
            </b-button>
          </div>
          <form v-if="showLoginForm" @submit.prevent="handleLoginClick">
            <div class="input-wr">
              <div :data-cy="emailLabelCy" class="login-label">{{emailLabelText}}</div>
              <div class="login-field">
                <b-form-input
                        :data-cy="emailFieldCy"
                        v-model="$v.form.email.$model"
                        class="notion-textfield"
                        autocomplete="off"
                        :state="validateState('email')"
                ></b-form-input>
                <b-form-invalid-feedback :tooltip="true"
                >Valid email is required.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="input-wr">
              <div :data-cy="passwordLabelCy" class="login-label">{{passwordLabelText}}</div>
              <div class="login-field">
                <b-form-input
                  :data-cy="passwordFieldCy"
                  type="password"
                  v-model="$v.form.password.$model"
                  class="notion-textfield"
                  autocomplete="off"
                  :state="validateState('password')"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="$v.form.password.$model !== ''"
                  :tooltip="true"
                  >Password need to have a letter, a number, a special
                  character, and be more than 8 characters
                  long.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  v-if="$v.form.password.$model === ''"
                  :tooltip="true"
                  >This is a required field.</b-form-invalid-feedback
                >
              </div>
            </div>
            <div class="row login-buttons-wrapper">
              <b-button
                class="lg login-aad-button mt-3 login-internal-button"
                variant="primary"
                type="submit"
                :data-cy="loginButtonCy"
              >
                {{loginButtonName}}
              </b-button>
            </div>
          </form>
          <div class="login-as-admin-wrapper">
            <b-row v-if="showLoginForm">
              <span class="login-as-admin-span mr-2">
                <forgot-password-modal></forgot-password-modal>
              </span>
              <div class="divider-vertical"></div>
              <span class="login-as-admin-span">
                <user-modal
                  :text="'Register User'"
                  :title="'Register User'"
                ></user-modal>
              </span>
            </b-row>
            <span
              v-if="isAadEnabled"
              class="login-as-admin-span ml-auto"
              v-on:click="$store.commit('TOGGLE_SHOW_LOGIN_FORM')"
              >{{
                showLoginForm
                  ? "Login with Active Directory"
                  : "Login with Email/Password"
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import UserModal from "@/components/users/UserModal.vue";
import ForgotPasswordModal from "@/components/users/ForgotPasswordModal.vue";
import Logo from "@/components/shared/Logo.vue";
import Constants from "@/helpers/constants"
export default {
  name: "loginview",
  components: { UserModal, ForgotPasswordModal, Logo },
  mixins: [validationMixin],
  data() {
    return {
      registerButtonName: "Sign Up",
      loginButtonCy: Constants.LOGIN_BUTTON_CY,
      loginButtonName: Constants.LOGIN_BUTTON_NAME,

      emailFieldCy: Constants.EMAIL_CY,
      emailLabelCy: Constants.EMAIL_LABEL_CY,
      emailLabelText: Constants.EMAIL_LABEL_TEXT,

      passwordFieldCy: Constants.PASSWORD_CY,
      passwordLabelCy: Constants.PASSWORD_LABEL_CY,
      passwordLabelText: Constants.PASSWORD_LABEL_TEXT,
      form: {
        email: "",
        password: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    isAadEnabled() {
      return this.$store.state.settings.isAadEnabled;
    },
    showLoginForm() {
      return this.$store.state.settings.showLoginForm;
    }
  },
  mounted() {
    if (this.$router.currentRoute.query.unauthorized) {
      this.$router.replace({ path: "/login", query: {} });
    }
    this.$store.commit("SHOW_HEADER", false);
  },
  methods: {
    validateState(name, validate = false) {
      if (validate) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    openModal() {
      let modal = this.$refs;
    },
    handleLoginClick() {
      this.$store.dispatch("handleInternalLogin", {
        username: this.$v.form.$model.email,
        password: btoa(this.$v.form.$model.password)
      });
    },
    handleOauthLoginClick() {
      window.location.href = "/login/oauth2/authorization/azure";
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300");
.app {
  height: 100%;
  width: 100%;
}
.login-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/earth_background.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  .form-wrapper {
    border-radius: 8px;
    padding: 5px 10px;
    background-color: #212c38;
    overflow: hidden;
    width: 50vw;
    .invalid-tooltip {
      position: inherit;
    }
    .header-wrapper {
      height: 150px;
      padding-top: 10px;

      .login-header-text-wrapper {
        .login-header-text {
          font-size: 16px;
        }
        .powered-by-text {
          margin-top: -5px;
          font-size: 12px;
        }
      }
      .header-left {
        place-content: space-between;
        display: flex !important;
        flex-direction: column;
        width: 100%;
      }

      .header-title {
        font-size: 32px;
      }
    }
    .forgot-password-wr {
      margin-top: -50px;
      .info-text {
        margin-bottom: 15px;
      }
    }
    .inputs-wrapper {
      background-color: rgb(33, 44, 56);
      padding: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      min-height: 300px;

      .input-wr {
        display: block;
        flex-direction: column;
        width: 300px;
        color: #e1ebf5;
        .form-group {
          margin-bottom: 5px;
          text-align: left;
        }
      }

      .login-as-admin-wrapper {
        margin-top: auto !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }

      .login-as-admin-span {
        cursor: pointer;
        color: rgb(206, 214, 224);
        font-size: 14px;
      }
      .divider-vertical {
        margin-right: 0.5rem;
        border-left: solid rgb(206, 214, 224) 1px;
      }

      .login-as-admin-span:hover {
        text-decoration: underline;
      }

      .sign-in-row {
        margin-top: 5px;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        width: 300px;
        height: 46px;
        .login-btn {
          margin-left: auto;
          font-size: 13px;
        }
      }

      .separator {
        width: 26px;
        background-color: #242424;
        height: 1px;
        margin-bottom: 15px;
        margin-top: 15px;
      }

      .login-buttons-wrapper {
        justify-content: center;
        margin-top: auto !important;
        margin-left: 0;
        margin-right: 0;
        width: 300px;
        .login-internal-button {
          padding: 0 !important;
          height: 36px !important;
        }
        .login-aad-button {
          font-size: 14px !important;
          line-height: initial !important;
          padding: 20px 36px;
          width: 100%;
          height: 56px;
          color: white;
        }
        .login-aad-button:hover {
          background-color: #fff;
          color: #0a78cc;
        }
      }

      .login-label {
        font-size: 14px;
        padding-top: 7px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .login-wrapper {
    .form-wrapper {
      width: 80vw;
      .header-wrapper {
        width: 80vw;
      }
    }
  }
}
</style>
